// 个人中心联系人模块
import {Request} from '@/http/request'

// 个人中心联系人列表
export function userList(parameter: any) {
    return Request.axiosInstance.post('/web/contact/list', parameter)
}

// 联系人增添
export function addUser(parameter: any) {
    return Request.axiosInstance.post('/web/contact/add', parameter)
}

// 联系人修改
export function editUser(parameter: any) {
    return Request.axiosInstance.post('/web/contact/edit', parameter)
}
// 解绑微信用户
export function unBindWechat(parameter: any) {
    // parameter ={"id":"id"};
    return Request.axiosInstance.post('/web/contact/unbind-wx', parameter)
}

// 联系人删除
export function deleteUser(parameter: any) {
    return Request.axiosInstance.post('/web/contact/delete', parameter)
}

export default {userList, addUser, editUser, unBindWechat, deleteUser}