<template>
  <a-modal :visible="dialogVisible"
           title="编辑联系人" width="40%" :style="{minHeight:'60%'}"
           :mask-closable="false"
           @cancel="dialogClose">

    <a-form ref="editFormRef" layout="horizontal" name="editForm"
            :label-col="labelCol" :wrapper-col="wrapperCol"
            :model="editForm" :rules="editFormRules">
      <a-form-item label="姓名" name="name">
        <a-input v-model:value="editForm.name" placeholder="请输入"/>
      </a-form-item>
      <a-form-item ref="phone" label="手机号码" name="phone">
        <a-input v-model:value="editForm.phone" placeholder="请输入"/>
      </a-form-item>
      <a-form-item ref="email" label="联系人邮箱" name="email">
        <a-input v-model:value="editForm.email" placeholder="请输入"/>
      </a-form-item>
      <a-form-item v-if="editForm.id" label="微信用户">
        <div v-if="editForm.openid" class="m3-flex">
          已绑定
          <a-popconfirm
              title="确定解绑微信用户?"
              ok-text="确定"
              cancel-text="取消"
              @confirm="unBindWechat(record)">
            <a-button class="m3-margin-left-s" type="primary" size="small" danger>解绑</a-button>
          </a-popconfirm>
        </div>
        <div v-else>
          <a-button type="primary" size="small" @click="bindWechatDialogVisible=!bindWechatDialogVisible">绑定微信用户</a-button>
        </div>
      </a-form-item>
      <a-form-item label="余额不足通知">
        <a-switch v-model:checked="editForm.balanceNotice" :checked-value="1" :un-checked-value="0"/>
      </a-form-item>
      <a-form-item label="质检风险通知">
        <a-switch v-model:checked="editForm.checkNotice" :checked-value="1" :un-checked-value="0"/>
      </a-form-item>
      <a-form-item label="线路质检违规通知">
        <a-switch v-model:checked="editForm.checkOrderNotice" :checked-value="1" :un-checked-value="0"/>
      </a-form-item>
    </a-form>
    <template #footer>
      <div class="m3-flex m3-justify-content-end">
        <a-button class="m3-margin-right-m" @click="dialogClose">取消</a-button>
        <a-button type="primary"
                  :loading="dialogLoading"
                  @click="dialogConfirm">确认
        </a-button>
      </div>
    </template>
  </a-modal>
  <BindWechatDialog v-model:dialog-visible="bindWechatDialogVisible" :uid="editForm.id"/>
</template>

<script>
import AModal from "ant-design-vue/lib/modal/Modal";
import {reactive, ref, toRaw, toRefs, watch} from "vue";
import EmptyUtil from "@/utils/common/EmptyUtil";
import {safePromise} from "@/utils/common/safePromise";
import contactApi from "@/api/web/contactApi";
import {useStore} from "vuex";
import AButton from "ant-design-vue/lib/button/button";
import BindWechatDialog from "@/views/web/personal/userList/BindWechatDialog.vue";
import ObjectUtil from "@/utils/common/ObjectUtil";
import {message} from "ant-design-vue";

export default {
  name: "EditUserDialog",
  emits: ['update:dialogVisible', 'confirm','refresh'],
  components: {BindWechatDialog, AButton, AModal},
  props: {
    dialogVisible: {
      type: Boolean,
      default: () => false,
    },
    record: {
      type: Object,
      default: {},
    }
  },
  setup(props, context) {

    let {record} = toRefs(props);
    let store = useStore();
    let editForm = reactive({
      balanceNotice: 0,
      checkNotice: 0,
      checkOrderNotice: 0,
      email: '',
      name: '',
      phone: '',
    });
    let resetForm = () => {
      editForm.balanceNotice = 0;
      editForm.checkNotice = 0
      editForm.checkOrderNotice = 0;
      editForm.email = '';
      editForm.name = '';
      editForm.phone = '';
      editForm.id = '';
      editForm.uid = '';
      editForm.openid = null;
    }
    let editFormRef = ref();
    let dialogLoading = ref(false);

    let bindWechatDialogVisible = ref(false);

    watch(bindWechatDialogVisible, (newV, preV) => {
      if (!newV && preV) {
        //由于不知道是否扫码过，在弹窗关闭时刷新列表数据，这样才可以得到openid。
        context.emit('refresh', true);
      }
    })

    watch(record, (newV, preV) => {
      console.log('watch record', newV)
      if (!EmptyUtil.isEmpty(newV)) {
        Object.assign(editForm, newV);
      } else {
        resetForm();
      }
    })

    const editFormRules = {
      name: [{required: true, message: '请输入姓名', trigger: 'blur',},],
      phone: [
        {
          required: true, message: '请输入手机号码',
          pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
          trigger: 'blur',
        },
      ],
      email: [{required: true, type: 'email', message: '请输入联系人邮箱', trigger: 'blur',},],
    }

    let unBindWechat = async () => {
      let res = await safePromise(contactApi.unBindWechat({id: editForm.id}));
      if (res?.code == 200) {
        message.success('解绑成功');
        editForm.openid = null;
        //同步到列表页
        let params = ObjectUtil.toParams(editForm);
        context.emit('confirm', params);
      }
    }

    let dialogClose = () => {
      context.emit('update:dialogVisible', false);
      // resetForm();
      // console.log('dialogClose', editForm)
    }
    let dialogConfirm = async () => {
      let valid = await editFormRef.value?.validate();
      // console.log('dialogConfirm valid', valid, editFormRef.value);
      if (valid) {
        dialogLoading.value = true;
        if (EmptyUtil.isEmpty(editForm.uid)) {
          editForm.uid = store.state.user.uid;
        }

        let params = ObjectUtil.toParams(editForm);
        let res;
        if (params.id) {
          res = await safePromise(contactApi.editUser(params));
        } else {
          res = await safePromise(contactApi.addUser(params));
        }
        // console.log('dialogConfirm res', res);
        if (res?.code == 200) {
          if (!EmptyUtil.isEmpty(res.data)) {
            Object.assign(params, res.data);
          }
          context.emit('update:dialogVisible', false);
          context.emit('confirm', params);
          // resetForm();
          // console.log('dialogConfirm', params, editForm)
        }
        dialogLoading.value = false;
      }
    }
    return {
      editForm, editFormRules, editFormRef,
      labelCol: {span: 4},
      wrapperCol: {span: 10},
      dialogClose, dialogConfirm, dialogLoading,
      bindWechatDialogVisible, unBindWechat,
    }
  },
}
</script>

<style scoped>

</style>